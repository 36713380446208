import React from 'react';

class Progress extends React.Component {
  constructor (props) {
    super(props);;
  }

  render () {
    const {width} = this.props;
    const classNames = `sp-bar progress-${width}`;

    return (
      <div className="skill-progress">
        <div className={classNames}></div>
      </div>
    );
  }
}

export default Progress;
