import React from 'react';

class BackgroundPattern extends React.Component {
  render () {
    return (
      <div className="pattern-background">
        <svg className="triangle" viewBox="0 0 1440 478" xmlns="http://www.w3.org/2000/svg"><path d="M0 478V0h1607.483z" fill="#FFF"/></svg>
        <svg className="triangle inverted" viewBox="0 0 1440 478" xmlns="http://www.w3.org/2000/svg"><path d="M0 478V0h1607.483z" fill="#FFF"/></svg>
        <svg className="triangle" viewBox="0 0 1440 478" xmlns="http://www.w3.org/2000/svg"><path d="M0 478V0h1607.483z" fill="#FFF"/></svg>
        <svg className="triangle inverted" viewBox="0 0 1440 478" xmlns="http://www.w3.org/2000/svg"><path d="M0 478V0h1607.483z" fill="#FFF"/></svg>
        <svg className="triangle" viewBox="0 0 1440 478" xmlns="http://www.w3.org/2000/svg"><path d="M0 478V0h1607.483z" fill="#FFF"/></svg>
        <svg className="triangle inverted" viewBox="0 0 1440 478" xmlns="http://www.w3.org/2000/svg"><path d="M0 478V0h1607.483z" fill="#FFF"/></svg>
        <svg className="triangle" viewBox="0 0 1440 478" xmlns="http://www.w3.org/2000/svg"><path d="M0 478V0h1607.483z" fill="#FFF"/></svg>
      </div>
    );
  }
}

export default BackgroundPattern;
