import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Hero from '../hero';
import BackgroundPattern from '../background-pattern';
import './about.css';
import Header from '../header';

class About extends React.Component {
  constructor (props) {
    super(props);

    this.state = {};
  }

  render () {
    return (
      <main>
        <Header></Header>
        <section className="my-about">
          
        </section>
        <BackgroundPattern></BackgroundPattern>
      </main>
    );
  }
}

export default About;
