import React from 'react';
import { NavLink } from "react-router-dom";
import ScrollAnimation from 'react-animate-on-scroll';
import Header from '../header';

class Hero extends React.Component {
  constructor (props) {
    super(props);

    this.state = {};
  }

  render () {
    return (
      <section>
        <Header></Header>
        <ScrollAnimation animateOnce={true} duration={1} animateIn="subtleSlideUp">
          <section className="hero-section">
            <div className="my-name">Terrell Weaver</div>
            <h1>Full-Stack Engineer</h1>
            <h2>Frontend Developer - UI/UX Designer - Backend Developer</h2>
            <button className="showLink">
              <span><a href="mailto:terrellwea@gmail.com?subject=Hey I saw your portfolio">Contact Me</a></span>
              <span><a href="mailto:terrellwea@gmail.com?subject=Hey, I saw your portfolio">terrellwea@gmail.com</a></span>
            </button>
          </section>
        </ScrollAnimation>
      </section>
    );
  }
}

export default Hero;
