import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Hero from '../hero';
import BackgroundPattern from '../background-pattern';
import ScrollAnimation from 'react-animate-on-scroll';
import Reveal from 'react-reveal/Reveal';
import Fade from 'react-reveal/Fade';

class Portfolio extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      quizshare: {
        activeImage: 0
      },
      mentalmasala: {
        activeImage: 0
      },
      acclaro: {
        activeImage: 0
      },
      isMaintanence: false,
    };

    this.images = {

      mentalmasala: [
        "/images/portfolio/mentalmasala-home.jpg"
        ,"/images/portfolio/mentalmasala-editor.jpg"
        ,"/images/portfolio/mentalmasala-listicle.jpg"
        ,"/images/portfolio/mentalmasala-author.jpg"
      ],
      quizshare: [
        "/images/portfolio/quizshare-analytics.png"
        ,"/images/portfolio/quizshare-editor.png"
        ,"/images/portfolio/quizshare-quiz.png"
        ,"/images/portfolio/quizshare-outcome.png"
      ],
      acclaro: [
        "/images/portfolio/acclaro-dashboard.jpg",
        "/images/portfolio/acclaro-api.jpg",
        "/images/portfolio/acclaro-admin.png",
        "/images/portfolio/acclaro-create-order.jpg",
      ]
    };
  }

  getNextSlide(direction, state, images) {
    const {activeImage:pos} = state;
    let nextSlide = direction ? pos + 1 : pos - 1;

    //if we are going backwards at the first image
    //go to the last image
    if(nextSlide < 0) nextSlide = images.length - 1;

    //if we are at the end of the arr
    //go to first image
    if(nextSlide > images.length - 1) nextSlide = 0;

    return nextSlide;
  }

  renderSlide(img, pos, stateKey) {
    const {activeImage} = this.state[stateKey];
    const isActive = pos == activeImage;

    return (
      <div key={pos} className={isActive ? 'project-panel-img active': 'project-panel-img'}>
        <img className="img-shadow" src={img}/>
      </div>
    );
  }

  render () {
    const {isMaintanence} = this.state;

    console.log('isMaintanence', isMaintanence)

    return (
      <main>
        <Hero></Hero>
        {!isMaintanence && (<>
        <section className="main-projects">
          <article className="project-panel">
            <div className="time-bubble">
              <span>2019 - 2024</span>
            </div>
            {/*<h3 className="spacer"><Link to="/project/acclaro">Acclaro</Link></h3>*/}
            <h3 className="spacer">Acclaro</h3>
            <p>Enterprise translation and localization services.</p>
            <p>(Updates in progress!)</p>
            {/*<section className="project-slider">
              <div className="ps-content">
                <button
                  onClick={
                    ()=> this.setState({
                      acclaro: {
                        activeImage: this.getNextSlide(0, this.state.acclaro, this.images.acclaro)
                      }
                    })
                  }
                  className="ps-nav-arrow backward"></button>
                <button
                  className="ps-nav-arrow forward"
                  onClick={
                    ()=> this.setState({
                      acclaro: {
                        activeImage: this.getNextSlide(1, this.state.acclaro, this.images.acclaro)
                      }
                    })
                  }
                  ></button>
                <figure className="project-panel-imgs ps-panels">
                  {this.images.acclaro.map((img, indx) => {
                    return this.renderSlide(img, indx, 'acclaro');
                  })}
                </figure>
              </div>
              <ul className="ps-tablist">
                {
                  this.images.acclaro.map((img, indx) => {
                    const active = this.state.acclaro.activeImage == indx;
                    return (
                      <li
                        key={indx}
                        className={active ? 'active' : ''}
                        onClick= {()=> this.setState({ acclaro: { activeImage: indx}})}></li>
                    );
                  })
                }
              </ul>
            </section>
            <Link className="link-bubble" to="/project/acclaro">See More</Link>*/}
          </article>
          <article className="project-panel">
            <div className="time-bubble">
              <span>2019</span>
            </div>
            <h3 className="spacer"><Link to="/project/quizshare">Quizshare</Link></h3>
            <p>Quiz Creation Software for Businesses.</p>
            <section className="project-slider">
              <div className="ps-content">
                <button
                  onClick={
                    ()=> this.setState({
                      quizshare: {
                        activeImage: this.getNextSlide(0, this.state.quizshare, this.images.quizshare)
                      }
                    })
                  }
                  className="ps-nav-arrow backward"></button>
                <button
                  className="ps-nav-arrow forward"
                  onClick={
                    ()=> this.setState({
                      quizshare: {
                        activeImage: this.getNextSlide(1, this.state.quizshare, this.images.quizshare)
                      }
                    })
                  }
                  ></button>
                <figure className="project-panel-imgs ps-panels">
                  {this.images.quizshare.map((img, indx) => {
                    return this.renderSlide(img, indx, 'quizshare');
                  })}
                </figure>
              </div>
              <ul className="ps-tablist">
                {
                  this.images.quizshare.map((img, indx) => {
                    const active = this.state.quizshare.activeImage == indx;
                    return (
                      <li
                        key={indx}
                        className={active ? 'active' : ''}
                        onClick= {()=> this.setState({ quizshare: { activeImage: indx}})}></li>
                    );
                  })
                }
              </ul>
            </section>
            <Link className="link-bubble" to="/project/quizshare">See More</Link>
          </article>
          <article className="project-panel">
            <div className="time-bubble">
              <span>2016 - 2018</span>
            </div>
            <h3 className="spacer"><Link to="/project/wta-fantasy">WTA Fantasy</Link></h3>
            <p>WTA's first online fantasy game.</p>
            <Reveal effect="fadeIn">
              <figure className="project-panel-imgs">
                <div className="project-panel-img">
                  <img src="/images/portfolio/wta-hero.png" alt="WTA Fantasy home" />
                </div>
              </figure>
            </Reveal>
            <Link className="link-bubble" to="/project/wta-fantasy">See More</Link>
          </article>
          <article className="project-panel">
            <div className="time-bubble">
              <span>2016</span>
            </div>
            <h3 className="spacer"><Link to="/project/mentalmasala">Mental Masala</Link></h3>
            <p>The Buzzfeed of India!</p>
            <section className="project-slider">
              <div className="ps-content">
                <button
                  onClick={
                    ()=> this.setState({
                      mentalmasala: {
                        activeImage: this.getNextSlide(0, this.state.mentalmasala, this.images.mentalmasala)
                      }
                    })
                  }
                  className="ps-nav-arrow backward"></button>
                <button
                  className="ps-nav-arrow forward"
                  onClick={
                    ()=> this.setState({
                      mentalmasala: {
                        activeImage: this.getNextSlide(1, this.state.mentalmasala, this.images.mentalmasala)
                      }
                    })
                  }
                  ></button>
                <figure className="project-panel-imgs ps-panels">
                  {this.images.mentalmasala.map((img, indx) => {
                    return this.renderSlide(img, indx, 'mentalmasala');
                  })}
                </figure>
              </div>
              <ul className="ps-tablist">
                {
                  this.images.mentalmasala.map((img, indx) => {
                    const active = this.state.mentalmasala.activeImage == indx;
                    return (
                      <li
                        key={indx}
                        className={active ? 'active' : ''}
                        onClick= {()=> this.setState({ mentalmasala: { activeImage: indx}})}></li>
                    );
                  })
                }
              </ul>
            </section>
            <Link className="link-bubble" to="/project/mentalmasala">See More</Link>
          </article>
        </section>
        <Reveal effect="subtleSlideDown">
          <div className="section-header">
            <h2>More Projects</h2>
          </div>
        </Reveal>
        <section className="sub-project-panels">
          <Reveal effect="subtleSlideRight">
            <div className="inner">
              <article className="sub-project-panel">
                <figure>
                  <img src="/images/portfolio/checkmate-blog.png" />
                  <Link className="link-bubble" to="/project/checkmate">View More</Link>
                </figure>
                <h3><Link to="/project/checkmate">Checkmate Creations Blog</Link></h3>
                <div className="sub-time">2016</div>
              </article>
              <article className="sub-project-panel">
                <figure>
                  <img src="/images/portfolio/index-d-home.png" />
                  <Link className="link-bubble" to="/project/index-d">View More</Link>
                </figure>
                <h3><Link to="/project/index-d">Index-D</Link></h3>
                <div className="sub-time">2014</div>
              </article>
              <article className="sub-project-panel">
                <figure>
                  <img src="/images/portfolio/halliday-baillie.png" />
                  <Link className="link-bubble" to="/project/hallidaybaillie">View More</Link>
                </figure>
                <h3><Link to="/project/hallidaybaillie">Haliday Baillie</Link></h3>
                <div className="sub-time">2013</div>
              </article>
            </div>
          </Reveal>
        </section>
        <footer>
          <h4>Let's Talk</h4>
          <button className="showLink">
            <span><a href="mailto:terrellwea@gmail.com?subject=Hey I saw your portfolio">Contact Me</a></span>
            <span><a href="mailto:terrellwea@gmail.com?subject=Hey, I saw your portfolio">terrellwea@gmail.com</a></span>
          </button>
        </footer>
        </>)}
        <footer className="maintenance-footer">
          <p>Making some updates. More to see soon!</p>
        </footer>
        <BackgroundPattern></BackgroundPattern>
      </main>
    );
  }
}

export default Portfolio;
