import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import ScrollToTop from '../hooks/scrollToTop';
import { Link } from "react-router-dom";
import './project.css';

//import projects
import checkmate from './checkmate';
import quizshare from './quizshare';
import hallidaybaillie from './hallidaybaillie';
import mentalmasala from './mentalmasala';
import wtafantasy from './wta-fantasy';
import indexd from './indexd';
import acclaro from './acclaro';
import Header from '../header';

const projects = {
  [checkmate.key]: checkmate
  ,[quizshare.key]: quizshare
  ,[hallidaybaillie.key]: hallidaybaillie
  ,[mentalmasala.key]: mentalmasala
  ,[wtafantasy.key]: wtafantasy
  ,[indexd.key]: indexd
  ,[acclaro.key]: acclaro
}

class Project extends React.Component {
  constructor(props) {
    super(props);

    const {projectname} = this.props.match.params;

    this.state = Object.assign({}, projects[projectname]);

    console.log('the state', this.state)
  }

  render() {
    const {state:project} = this;

    const buildImg = (img, project, indx) => {
      if(typeof img !== 'object') img = {imageName: img};

      const {imageName, hasShadow, description} = img;
      const props = {className: hasShadow ? 'has-shadow' : ''};

      return (
        <li key={indx}>
          {description && <h3>{description}</h3>}
          <img {...props} src={'/images/' + project + '/' + imageName} />
        </li>
      );
    };

    return (
      <ScrollToTop>
        <CSSTransition timeout={200} classNames="color-change">
          <main className="project-page color-transition">
            <Header hasBack={true} className="subtleSlideDown"></Header>
            <section className="project-page-hero">
              {project.link && (<a target="_blank" href={project.link} className="project-link">View Website</a>)}
              <h1>{project.title}</h1>
              <h2>{project.subtitle}</h2>
              <article className="project-description">
                <p><strong>Year:</strong> {project.description.year}</p>
                {project.description.company && <p><strong>Company & Position:</strong> {project.description.company}, {project.description.position}</p>}
                <p><strong>Role:</strong> {project.description.role}</p>
                <p><strong>Objective:</strong> {project.description.objective}</p>
              </article>
              <article className="project-skills">
                {project.skills.map((item, indx) => {
                  return (<p key={indx}><strong>{item.type}:</strong>  {item.value}</p>)
                })}
              </article>
            </section>
            <ul className="project-images subtleSlideUp">
              {project.images.map((item, indx) => {
                return buildImg(item, project.key, indx);
              })}
            </ul>
          </main>
        </CSSTransition>
      </ScrollToTop>
    );
  }
}

export default Project;
